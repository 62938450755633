<template>
    <div class="login">
        <div class="content">
            <div class="title">
                <img src="../assets/sgy2.png">
            </div>
            <div class="subtitle">重置密码</div>
            <div class="block">
                <input type="password" autocomplete="new-password" hidden>
                <Cinput title="手机号" v-model="phone" />
                <Cinput title="重置密码" type="password" v-model="password" />
                <vue-simple-verify width='313' style="margin: 10px 33px;" ref="verify" @success="success" />
                <div class="ver-block">
                    <input v-model="verCode" class="input-large ext-input" type="text" autocomplete="off">
                    <div v-loading="buttonLoading" class="get-code" @click="getCode">{{codeTxt}}</div>
                </div>
                <div :class="hasInput?'active':''" class="btn" @click="next">确定重置</div>
            </div>
        </div>
        
    </div>
</template>
<script>
import Cinput from './userInput';
import VueSimpleVerify from 'vue-simple-verify'
import 'vue-simple-verify/dist/vue-simple-verify.css'
export default {
    data() {
        return {
            phone:'',
            password:'',
            isRemeber: false,
            hasDrag:false,
            verCode:'',
            codeTxt:'获取验证码',
            buttonLoading: false,
            pageLoading: false,
        }
    },
    computed:{
        hasInput(){
            if(this.phone !== '' && this.password !== ''){
                return true;
            }else{
                return false;
            }
        }
    },
    components:{
        Cinput,
        'vue-simple-verify':VueSimpleVerify,
    },
    methods: {
        async next(){
          // this.$emit('changeStep',5);
          // this.$emit('changeStep',{val:5,temToken:123});
          if(!this.validataItem('phone')){
            return false;
          }else if(!this.validataItem('password')){
            return false;
          }else if(!this.validataItem('verCode')){
            return false;
          }
          this.pageLoading = true;
          try {
            const res = await this.$http.post('/api/user/resetpwd',{
              mobile:this.phone,
              captcha:this.verCode,
              newpassword:this.password
              })
            this.pageLoading = false;
            if(res){
                this.$toast('重置密码成功');
                const tem = setTimeout(() => {
                    window.location.href = "/login";
                    clearTimeout(tem);
                }, 1500);
            }
          } catch (error) {
            this.pageLoading = false;
            this.$toast(error.message||'请求失败');
          }
          
        },
        success(){
          this.hasDrag = true;
        },
        validataItem(type){
          if(type === 'phone'){
            const myreg=/^[1][3,4,5,6,7,8,9][0-9]{9}$/;
            const phone = this.phone;
            console.log('this.phone', phone);
            if(phone===''){
                this.$toast('请输入手机号')
                return false;
            }else if(!myreg.test(phone)){
                this.$toast('请输入正确的手机号')
                return false;
            }else{
                return true;
            }
          }else if(type === 'password'){
            if(this.password===''){
                this.$toast('请输入重置密码');
                return false;
            }else{
                return true;
            }
          }else{
            if(this.verCode===''){
                this.$toast('请输入验证码');
                return false;
            }else{
                return true;
            }
          }
          
        },
        async getCode(){
          const phone = this.phone;
          if(this.codeTxt !== '获取验证码'){
            return false;
          }
          if(!this.validataItem('phone')){
                return false;
            }else if(!this.hasDrag){
                this.$toast('请按住上方滑块，拖动到最右边');
                return false;
            }
            this.buttonLoading = true;
            try {
              const res = await this.$http.post('/api/user/sendMsg',{mobile:phone})
              console.log('res',res);
              this.buttonLoading = false;
              console.log('res',res);
              if(res){
                this.codeTxt = '60s';
                let count = 60;
                const temInterval = setInterval(() => {
                  count --;
                  this.codeTxt = `${count}s`;
                  if(this.codeTxt === '0s'){
                    clearInterval(temInterval);
                    this.codeTxt = '获取验证码'
                  }
                }, 1000);
              }
            } catch (error) {
              this.buttonLoading = false;
              this.$toast(error.message||'请求失败');
            }
            
        },
    },
}
</script>
<style lang="scss" scoped>
input:-internal-autofill-selected{
    background: transparent !important;
}
.login{
    width: 100%;
    height: 100%;
    background: url('../assets/bglogin.png');
    background-attachment:fixed;
    background-position:center;
    position: relative;
}
.content{
    width: 380px;
    position: absolute;
    left: 50%;
    margin-left: -190px;
    top: 100px;
    height: 100px;
    color: #fff;
    .title{
        text-align: center;
        font-size: 20px;
        font-weight: bold;
    }
    .subtitle{
        font-size: 18px;
        font-weight: bold;
        padding-left: 20px;
        margin-top: 20px;
    }
}
.block{
    width: 380px;
    height: 335px;
    background: #3C72CA;
    border: 1px solid #FFFFFF;
    border-radius: 12px;
    margin-top: 30px;
    padding: 37px 0;
    box-sizing: border-box;
}


.btn{
    width: 317px;
    margin: auto;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 16px;
    color:#3699FA;
    background: #fff;
    margin-top: 25px;
    border-radius: 8px;
}
.active{
    cursor: pointer;
    background: #3699FA !important;
    color: #fff !important;
}

.ver-block{
    width:317px;
    margin: auto;
    position: relative;
    input{
        border: 1px solid #fff;
        border-radius: 9px;
        height: 37px;
        width: 205px;
        outline: none;
        background: transparent;
        padding-left: 10px;
        box-sizing: border-box;
    }
    .get-code{
        cursor: pointer;
        position: absolute;
        right: 0px;
        top: 2px;
        width: 100px;
        border-radius: 4px;
        padding: 9px 0px;
        text-align: center;
        background: #46A5FE;
        color: #fff;
        font-size: 13px;
    }
}



</style>
